import {
  createRouter,
  createWebHistory
} from 'vue-router';
import store from '@/store/store.js';

const routes = [{
    path: '/home',
    name: 'HomePage',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Settings.vue')
  },
  {
    path: '/login',
    name: 'LoginPage',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/export',
    name: 'ExportPage',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Export.vue')
  },
  {
    path: '/phoneChange',
    name: 'PhoneChange',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/PhoneChange.vue')
  },
  {
    path: '/employee',
    name: 'employee',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/EmployeeSearch.vue')
  },
  {
    path: '/rollback',
    name: 'rollback',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Rollback.vue')
  },
  {
    path: '/createTruckproUser',
    name: 'CreateTruckproUsers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/CreateTruckproUsers.vue')
  },
  {
    path: '/createCarrierPortalUser',
    name: 'CreateCarrierProtalUsers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/CreateCarrierPortalUsers.vue')
  },
  {
    path: '/newAgentSetup',
    name: 'NewAgentSetup',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/NewAgentSetup.vue')
  },
  {
    path: '/deactivateEmails',
    name: 'DeactivateEmails',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/DeactivateEmails.vue')
  },
  {
    path: '/welcomeEmail',
    name: 'WelcomeEmail',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/WelcomeEmail.vue')
  },
  {
    path: '/updateShipmentId',
    name: 'UpdateShipmentId',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/UpdateShipmentId.vue')
  },
  {
    path: '/reactivateLTLLoad',
    name: 'ReactivateLTLLoad',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/ReactivateLTLLoad.vue')
  },
  {
    path: '/insertEDI210',
    name: 'InsertEDI210',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/InsertEDI210.vue')
  },
  {
    path: '/addCustomerPastDueExemption',
    name: 'AddCustomerPastDueExemption',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/AddCustomerPastDueExemption.vue')
  },
  {
    path: '/changeLoadCustomer',
    name: 'ChangeLoadCustomer',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/ChangeLoadCustomer.vue')
  },
  {
    path: '/changeLoadCarrier',
    name: 'ChangeLoadCarrier',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/ChangeLoadCarrier.vue')
  },
  {
    path: '/changeLTLLoadCustomer',
    name: 'ChangeLTLLoadCustomer',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/ChangeLTLLoadCustomer.vue')
  },
  {
    path: '/fuelCostUpdate',
    name: 'FuelCostUpdate',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/FuelCostUpdate.vue')
  },
  {
    path: '/palletReqUpdate',
    name: 'PalletReqUpdate',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/PalletReqUpdate.vue')
  },
  {
    path: '/batchRating',
    name: 'BatchRating',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/BatchRating.vue')
  },
  {
    path: '/searchCustomerPortalUsers',
    name: 'CustomerPortalUsersSearch',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/CustomerPortalUsersSearch.vue')
  },
  {
    path: '/addEmployeeToHelpDesk',
    name: 'AddEmployeeToHelpDesk',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/AddUserToHelpDesk.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.state.employeeId;

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
});

export default router;