<template>
  <footer>
    <!-- <p>&copy; {{ year }} Logistic Dynamics LLC </p> -->
  </footer>
</template>

<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>

<style lang="scss">
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
}
</style>
