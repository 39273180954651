import axios from "axios";
import BaseRepository from "@/components/repositories/baseRepository";

export class ApplicationSupportRepository extends BaseRepository {
    constructor() {
        super();
    }

    async getSettings() {
        var url = BaseRepository.baseUrl + '/api/applicationsupport/getSettings';
        var token = await super.getToken();
        let returnResponse;

        await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(function (response) {
                returnResponse = response.data;
            })
            .catch(function (error) {
                alert(error);
            });

        return returnResponse;
    }

    async exportAudit(loadId, doneByEmployeeId, requestedByEmployeeId, type) {
        var url = BaseRepository.baseUrl + '/api/applicationsupport/exportaudit/';
        var token = await super.getToken();
        let returnResponse;
        let params = {
            DoneByEmployeeID: doneByEmployeeId,
            RequestedByEmployeeID: requestedByEmployeeId,
            LoadID: loadId,
            Type: type,
            DateTime: new Date(Date.now())
        }

        await axios({
                method: 'PUT',
                url: url,
                data: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(function (response) {
                returnResponse = response.status === 200;
            })
            .catch(function (error) {
                alert(error);
            });

        return returnResponse;
    }

    async rollbackAudit(loadId, doneByEmployeeId, billInvoiceId, type) {
        var url = BaseRepository.baseUrl + '/api/applicationsupport/rollbackaudit/';
        var token = await super.getToken();
        let returnResponse;
        let params = {
            DoneByEmployeeID: doneByEmployeeId,
            LoadID: loadId,
            Type: type,
            BillInvoiceID: billInvoiceId,
            BatchNumber: '',
            DateTime: new Date(Date.now())
        }

        await axios({
                method: 'PUT',
                url: url,
                data: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(function (response) {
                returnResponse = response.status === 200;
            })
            .catch(function (error) {
                alert(error);
            });

        return returnResponse;
    }

    
}