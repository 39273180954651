import axios from 'axios';

export default class BaseRepository {
    static baseUrl = process.env.VUE_APP_API_URL;
    constructor() {}

    async getToken() {
        var url = BaseRepository.baseUrl + '/api/token';
        var login = new Object();
        login.username = 'mcappuzzo';
        login.password = this.encryptPassword('5203');
        login.PortalName = 'LDI Corporate';
        let returnResponse;

        await axios({
                method: 'POST',
                url: url,
                data: JSON.stringify(login),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function (response) {
                returnResponse = response.data;
            })
            .catch(function (error) {
                alert(error);
            });

        return returnResponse;
    }

    encryptPassword(password) {
        var result = [];

        for (var i = 0; i < password.length; i++) {
            var charByte = password.charCodeAt(i);
            var newCharByte = charByte + 5;
            result.push(newCharByte);
        }

        return String.fromCharCode.apply(String, result);
    }
}