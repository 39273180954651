import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import ColumnGroup from "primevue/columngroup";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primevue/resources/themes/saga-blue/theme.css"
import PrimeVue from "primevue/config";
import "./assets/css/app.css";
import store from '@/store/store.js';

const app = createApp(App);
app.component("DataTable", DataTable);
app.component("ColumnItem", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("InputText", InputText);
app.use(PrimeVue);
app.use(store);
app.use(router);
app.mount("#app");