import axios from 'axios';

export class MicrosoftRepository {
    constructor() {}

    async getGraph(token) {
        var url = new URL('https://graph.microsoft.com/beta/me');
        let returnResponse;

        await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(function (response) {
                returnResponse = response.data;
            })
            .catch(function (error) {
                alert(error);
            });

        return returnResponse;
    }
}