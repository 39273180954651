<template>
  <header class="header">
    <img alt="LDI logo" src="@/assets/logo2.png" style="width: 5%; height: 5%" />
    <p />
    <h1>Application Support Tool</h1>
    <div v-if="this.$store.state.employeeId" class="nav">
      <span class="space" v-if="!getMode()">Development</span>
      <router-link to="/home" class="space">Home</router-link>
      <router-link to="/settings" class="space">Settings</router-link>
      <router-link to="/login" class="space" v-on:click="logout()">Logout</router-link>
    </div>
    <div v-else class="nav">
      <button class='msButton' type="submit" v-on:click="login()">
      <img src="@/assets/ms_logo.png" class='msButtonSpace' />
      <span class='msButtonSpace'>Sign in with Microsoft</span>
    </button>
    </div>
  </header>
</template>

<script>
import * as msal from "@azure/msal-browser";
import { MicrosoftRepository } from "@/components/repositories/microsoftRepository";

export default {
  name: "AppHeader",
  methods: {
    async login() {
      const msalConfig = this.$store.state.msalConfig;
      const msalInstance = new msal.PublicClientApplication(msalConfig);
      var request = {
        scopes: ["User.Read"]
      };

      try {
        var response = await msalInstance.loginPopup(request);

        if (response.errorCode !== undefined) {
          alert("Login failed.");
        }
        else {
          this.$store.commit('setAccessToken', response.accessToken);
          var microsoftRepository = new MicrosoftRepository();
          var graphResponse = await microsoftRepository.getGraph(this.$store.state.accessToken);

          if (graphResponse.employeeId === null) {
            throw "Not authorized to use this application.";
          }

          await this.$store.commit('setSettings', null);
          this.$store.commit('setEmployeeId', graphResponse.employeeId);
          this.$store.commit('setName', response.account.name);
          this.$store.commit('setUser', response.account.username);
          this.$router.push("/home");
        }
      }
      catch (err) {
        alert(err);
      }
    },
    logout() {
      if (this.$store.state.employeeId !== '') {
        const msalConfig = this.$store.state.msalConfig;
        const msalInstance = new msal.PublicClientApplication(msalConfig);
        msalInstance.logoutRedirect({
          onRedirectNavigate: () => {
            // Return false if you would like to stop navigation after local logout
            return false;
          }
        });

        this.$store.dispatch('clear');
        console.log('signed out');
      }
    },
    getMode() {
      return process.env.NODE_ENV === 'production';
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #014c5b;
  color: white;
}

header {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 1rem;

  p {
    margin-left: 1rem;
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}

.space {
  margin: 12px;
}

.msButton {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #5e5e5e;
  background: #ffffff;
  border: 1px solid #8C8C8C;
  border-radius: 0%;
  width: 275px;
  height: 50px;
  display: inline-flex;
  align-items: center;
}

.msButtonSpace {
  margin: 12px;
}

</style>
