<template>
  <app-header />
  <router-view />
  <app-footer />
</template>

<script>
import AppHeader from "@/components/common/AppHeader.vue";
import AppFooter from "@/components/common/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
<style>
@font-face {
  font-family: "Metropolis";
  src: local("Metropolis"),
    url(./assets/fonts/Metropolis/Metropolis-Regular.ttf) format("truetype"),
    url(./assets/fonts/Metropolis/Metropolis-Bold.ttf) format("truetype");
}
</style>
