import {
    createStore
} from 'vuex';
import {
    ApplicationSupportRepository
} from '@/components/repositories/applicationSupportRepository';

const store = createStore({
    state() {
        return {
            msalConfig: {
                auth: {
                    clientId: 'ae366d3b-84c4-412d-82e3-68cbb921947d',
                    authority: 'https://login.microsoftonline.com/e2237bb9-f284-4459-ad37-e88ed0005c4a',
                    protocolMode: "AAD"
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            },
            accessToken: '',
            employeeId: '',
            name: '',
            user: '',
            settings: '',
            department: '',
            version: process.env.VUE_APP_VERSION || 'Not detected'
        }
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        },
        setEmployeeId(state, id) {
            state.employeeId = id;
        },
        setName(state, name) {
            state.name = name;
        },
        setUser(state, user) {
            state.user = user;
        },
        setDepartment(state, department) {
            state.department = department;
        },
        async setSettings(state, settings) {
            if (settings === null) {
                var applicationSupportRepository = new ApplicationSupportRepository();
                const response = await applicationSupportRepository.getSettings();
                state.settings = JSON.stringify(response);
            } else {
                state.settings = settings;
            }
        }
    },
    actions: {
        clear(context) {
            context.commit('setSettings', '');
            context.commit('setEmployeeId', '');
            context.commit('setName', '');
            context.commit('setUser', '');
            context.commit('setDepartment', '');
        }
    },
    getters: {
        getSettings: (state) => (settingValue) => {
            var settings = JSON.parse(state.settings);
            var foundSetting = settings.find(x => x.setting === settingValue);

            return foundSetting.value;
        },
        appVersion: (state) => {
            return state.version;
        }
    }
});

export default store;